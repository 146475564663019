<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>用户搜索关键字</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">

            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="关键字">
                                <el-input v-model="search.key"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button
                            icon="el-icon-download"
                            size="medium"
                            type="primary"
                            @click="exportList"
                            >导出
                        </el-button>
                    </div>

                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    v-loading="loading"
                    :data="tableData"
                    border
                    size="medium">
                <el-table-column
                        prop="key"
                        label="关键字">
                </el-table-column>
                <el-table-column
                        prop="sum"
                        label="搜索次数">
                    <template slot-scope="scope">
                        {{ scope.row.sum + (scope.row.virtual_sum||0) }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="update_time"
                        label="更新时间"
                        min-width="150">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button  @click="onDynamicData(scope.row)" type="primary"
                            size="mini">数据优化
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>
        </div>

        <el-dialog title="数据优化" :visible.sync="isDynamicModal" width="50%"
                :close-on-press-escape="false" :close-on-click-modal="false">
                <el-row>
                    <el-form size="medium" ref="form" label-width="120px">
                        <el-col :span="24">
                            <el-form-item label="关键字">
                                <span class="form-span-text">{{ dynamicData.key }}</span>
                            </el-form-item>
                            <el-form-item label="当前搜索数">
                                <span class="form-span-text">{{ dynamicData.sum }}</span>
                            </el-form-item>
                            <el-form-item label="优化搜索数">
                                <el-input-number v-model="dynamicData.virtual_sum" :min="0" :max="100000000" label="描述文字"></el-input-number>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
                <el-row style="margin: 10px 0px;">
                    <el-button size="medium" type="primary" @click="saveDynamicData">确
                        定
                    </el-button>
                    <el-button size="medium" @click="isDynamicModal = false">取 消</el-button>
                </el-row>
            </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '用户搜索关键字',
            issearch: false,
            searchtime: null,     //时间
            loading: true,
            search: {},         // 搜索条件
            tableData: [],      // 列表内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            isDynamicModal: false,
            dynamicData: {
                key: '',
                sum: '',
                virtual_sum: '',
            },
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 初始化
        init() {
            this.searchtime = null
            this.search = {
                key: '',
            }
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "user.searchlog.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.$message.error(json.message);
                }
            })
        },
        onDynamicData(data) {
            this.dynamicData = {...data};
            this.isDynamicModal = true
        },
        saveDynamicData() {
            let postdata = {
                api_name: "user.searchlog.setnum",
                token: this.Tool.get_l_cache('token'),
                id: this.dynamicData.id,
                virtual_sum: this.dynamicData.virtual_sum,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            // this.page = 1
                            this.info = {}
                            this.visitsVisible = false
                            this.getlist()
                        }
                    });
                    this.isDynamicModal = false
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        exportList() {
            let postdata = {
                api_name: "user.searchlog.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
                export: "yes",
            };
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true;
            this.Tool.post_data("oss", postdata, (res) => {
                if (res.code === 10005) {
                    this.Tool.errormes(res);
                } else {
                    location.href = res;
                }
                this.loading = false;
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}


</style>
